import React from "react"

import Layout from "../../../../components/layout-static"
import Seo from "../../../../components/seo"
import { Link } from "gatsby"

const Page = () => {
  const metadata = {
    title: "Connecticut Alcohol Liability Lawyers - Bartlett & Grippe",
    description:
      "Injured due to over-serving of alcohol? Contact liquor liability attorneys at Bartlett & Grippe. We'll fight for your rights. Contact us for a free consultation.",
    heroH1: "Alcohol Related Injury",
    heroH2: "When experience, integrity, and results matter.",
    heroImage: "hero-alcohol.jpg",
    heroImageAlt: "A glass being filled with beer from a tap",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} description={metadata.description} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <h2 className="uppercase">CT Alcohol-Related Accident Lawyers</h2>
            <h3 className="uppercase">
              Your Injuries May Fall Under CT Dram Shop Liability Laws
            </h3>
            <p className="mb-8">
              If you or a loved one has been injured as a result of someone
              having been served too much alcohol, contact the liquor liability
              attorneys at Bartlett & Grippe, LLC now. We will help you
              determine if it is possible to bring a personal injury suit under
              the Connecticut dram shop laws. If we find you have a viable case,
              we will fight for your rights and
            </p>

            <h2 className="uppercase">
              Drunk Driver Accident? Bar fight? Get the Representation you
              Deserve!
            </h2>
            <p className="mb-8">
              If you are injured in an auto accident by a drunk driver, in
              addition to bringing a claim against the drunk driver you may have
              the ability to recover from the bar or restaurant who served
              drinks to the driver. In order to do this effectively, you will
              need the assistance of a seasoned liquor liability attorney.
            </p>

            <h2 className="uppercase">What is a “Dramshop” or Dram Shop?</h2>
            <p className="mb-8">
              This is an old legal term that has passed down through the years.
              It relates to establishments that serve liquor such as bars,
              nightclubs, or sometimes restaurants. Liquor was once dispensed by
              the liquid measure the dram, hence the term dram shop.
            </p>

            <p className="mb-8">
              Connecticut established dram shop laws to prohibit bartenders and
              waitstaff from serving people who were already showing signs of
              intoxication. Under the laws, if someone who is served alcohol
              while visibly intoxicated injures another person, the bar or
              restaurant who continued to serve them may be liable for the
              injuries sustained. Liquor liability, also known as “Dram Shop
              Liability” may also cover injuries sustained in a fight or other
              physical altercation if the club owner continued to serve alcohol
              to a person who was obviously intoxicated. CONN. GEN. STAT. §
              30-102. Dram Shop Act; Sale of alcoholic beverages to an
              intoxicated person.
            </p>
            <h2 className="uppercase">
              Liquor liability attorneys who know the dram shop laws
            </h2>
            <h3 className="uppercase">Experience Matters</h3>
            <p className="mb-8">
              Although every case is unique and past results are not a guarantee
              of future success, our attorneys have been recognized in 2010 and
              2013 for obtaining one of the largest verdicts on record in the
              state of Connecticut in the area of liquor liability or dram shop
              liability. They has also been featured in the Connecticut Law
              Tribune’s Personal Injury Yearbook as a result of dram shop
              verdicts. Our attorneys have successfully represented dram shop
              cases before the Superior Courts, the Appellate Courts, and the
              Connecticut Supreme Court.
            </p>
            <Link to="/contact-us/">
              <button className="mb-8 mx-auto cta-button-green">
                Contact us today for a free confidential consultation
              </button>
            </Link>

            <h2 className="uppercase">
              When is a Bar or Restaurant Owner Potentially Liable for Injuries?
            </h2>
            <p className="mb-8">
              When a person is injured as a result of a drunk driver, they may
              be able to file a lawsuit seeking to hold the bar or restaurant
              who served the drunk driver while intoxicated. The plaintiff must
              prove the following elements to succeed in a dram shop lawsuit:
            </p>
            <ul className="list-disc list-outside ml-6 mb-8">
              <li>
                <strong>Service of Alcohol While Visibly Intoxicated:</strong>{" "}
                Connecticut law imposes strict liability for injuries that occur
                as a result of a person’s intoxication if the plaintiff can
                prove that the bar or restaurant continued to serve alcohol to a
                patron who was visibly intoxicated. This means that a plaintiff
                must prove that the drunk driver was showing visible signs of
                intoxication such as unsteady gait, glassy eyes, slurred speech,
                and other signs, yet the bar continued to serve the individual.
                Proof of service after visible intoxication generally requires a
                thorough investigation by your team at Bartlett & Grippe, LLC,
                including interviewing witnesses and securing security footage
                from the bar or restaurant.
              </li>
              <li>
                <strong>Damages:</strong> The injury caused by the intoxicated
                patron must have caused either economic or non-economic damages.
                This means that a bar or restaurant owner cannot be held liable
                unless the injured person incurred medical bills, lost wages or
                damage to their future earning capacity, or pain and suffering.
              </li>
            </ul>
            <h2 className="uppercase">
              What compensation are you entitled to?
            </h2>
            <p className="mb-8">
              In personal injury cases, compensatory damages are awarded to
              compensate the injured person for the harm caused by the
              intoxicated person. Compensatory damages attempt to make the
              victim “whole” again and provide financial compensation for
              economic and non-economic losses. Also known as actual damages,
              economic losses that may be covered include:
            </p>

            <ul className="list-disc list-outside ml-6 mb-8">
              <li>
                <strong>Medical expenses:</strong> These damages cover the cost
                of hospital stays, doctor visits, physical therapy, prescription
                drugs, assistive devices, and similar expenses. When an injury
                is severe or permanent, the personal injury victim may also be
                awarded compensation for future medical expenses.
              </li>
              <li>
                <strong>Lost wages:</strong> If you must take time away from
                work to recover from your injuries you may be able to collect
                compensation for lost wages.
              </li>
              <li>
                <strong>Loss of earning capacity:</strong> When a person is
                unable to earn the same amount of money as he or she did prior
                to their accident, they may be awarded compensation for a loss
                of earning capacity.
              </li>
              <li>
                <strong>Pain and suffering:</strong> Damages for pain and
                suffering typically compensate the victim for the physical pain
                caused by their injuries. These damages may also include
                compensation for emotional distress, which refers to the
                anxiety, depression, fear, frustration, and other mental
                sufferings that can develop as a result of an injury.
              </li>
              <li>
                <strong>Loss of Consortium:</strong> The spouse of the injured
                party may be able to recover compensation for the loss of
                marital benefits. Loss of companionship, sexual relations,
                affection, and comfort are among the compensable marital
                benefits. Loss of consortium damages is typically awarded in
                cases of life-changing or permanent injuries.
              </li>
              <li>
                <strong>Additional Damages May be Available:</strong> In the
                State of Connecticut, if a bartender continues to serve a patron
                who is obviously intoxicated, the owner may be held liable for
                punitive damages in addition to the damages available to
                compensate the victim for their injuries. The attorneys at
                Bartlett & Grippe, LLC, work hard to investigate the bar’s
                knowledge of the individual’s intoxication to determine whether
                our clients may be entitled to additional compensation due to a
                bartender’s recklessness.
              </li>
            </ul>
            <p className="mb-8">
              Whether you are a patron of a restaurant or bar or you are simply
              walking home from an evening out, you should not have to be
              worried about being injured by a person who has consumed too much
              alcohol. Serious injuries sustained in fights, car accidents or
              simply walking down the street and being struck by an out of
              control vehicle can be life-altering and in some cases can result
              in death.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
